md-stepper {
  display: block;
}
md-steppers-header,
md-steppers-mobile-header,
md-step-actions {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.md-steppers-header-region {
  z-index: 1;
}
@media (max-width: 599px) {
  md-stepper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  md-stepper > div {
    min-width: 0px;
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
  }
  .md-steppers-content {
    min-height: 0px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  md-step.md-active {
    min-height: 0px;
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  md-step.md-active .md-stepper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
    min-height: 0px;
  }
  md-steppers-scope {
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
  }
  md-step-body {
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
  }
}
.md-steppers {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column;
}
.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):not(.md-completed):hover,
.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator.md-editable.md-completed:hover {
  cursor: pointer;
}
.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):not(.md-completed):hover,
.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator.md-editable.md-completed:hover,
.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):not(.md-completed):hover
  .md-stepper-indicator-wrapper,
.md-steppers:not(.md-steppers-linear)
  .md-stepper-indicator.md-editable.md-completed:hover
  .md-stepper-indicator-wrapper {
  background-color: #f6f6f6;
}
.md-steppers-header {
  margin: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  border-radius: 0;
  -ms-flex-align: start;
  -webkit-box-align: start;
          align-items: flex-start;
}
.md-steppers-horizontal .md-stepper-indicator {
  min-height: 72px;
}
.md-steppers-horizontal
  .md-stepper-indicator:first-child
  .md-stepper-indicator-wrapper {
  padding-left: 16px;
}
.md-steppers-horizontal .md-stepper-indicator:after {
  width: 999em;
  height: 1px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  background-color: #e8e8e8;
  content: ' ';
}
.md-stepper-indicator {
  padding: 0 16px;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
          flex: 1 0 auto;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: start;
  -webkit-box-align: start;
          align-items: flex-start;
  overflow: hidden;
  cursor: default;
  background: none;
  border: none;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-stepper-indicator:focus {
  outline: none;
}
.md-stepper-indicator.md-completed .md-stepper-number,
.md-stepper-indicator.md-active .md-stepper-number {
  background-color: #2196f3;
}
.md-stepper-indicator.md-completed .md-stepper-title,
.md-stepper-indicator.md-active .md-stepper-title {
  color: rgba(0, 0, 0, 0.87);
}
.md-stepper-indicator.md-active .md-stepper-title,
.md-stepper-indicator.md-editable .md-stepper-title {
  font-weight: 500;
}
.md-stepper-indicator.md-error .md-stepper-title {
  color: #f44336;
}
.md-stepper-indicator:first-child {
  padding-left: 0;
}
.md-stepper-indicator:last-child {
  padding-right: 0;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.md-stepper-indicator:last-child .md-stepper-indicator-wrapper {
  padding-right: 24px;
}
.md-stepper-indicator .md-ripple-container {
  z-index: 3;
}
.md-stepper-indicator-wrapper {
  padding: 0 8px 0 0;
  position: relative;
  z-index: 2;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #fff;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-stepper-number {
  width: 24px;
  height: 24px;
  margin: 0 8px;
  border-radius: 24px;
  background-color: #bdbdbd;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}
.md-stepper-number.ng-hide {
  -webkit-transition: none;
  transition: none;
}
.md-error .md-stepper-error-indicator {
  margin: 0 8px;
  background-color: #fff;
}
.md-error .md-stepper-error-indicator md-icon {
  color: #f44336;
}
.md-error .md-stepper-error-message {
  color: #f44336;
  font-weight: 400;
}
.md-stepper-icon {
  width: 18px;
  height: 18px;
  position: relative;
  top: -1px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.md-stepper-icon.md-stepper-icon-edit {
  width: 14px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
}
.md-stepper-title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: column nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column nowrap;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 14px;
  line-height: 24px;
}
.md-steppers-content {
  position: relative;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
}
.md-stepper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.md-stepper.md-active {
  position: relative;
  pointer-events: auto;
}
.md-steppers-actions {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}
.md-stepper-optional .md-stepper-title {
  text-align: left;
  white-space: nowrap;
}
.md-stepper-optional .md-stepper-title small:not(.md-stepper-error-message) {
  color: rgba(0, 0, 0, 0.54);
}
.md-stepper-optional .md-stepper-title small {
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 12px;
  line-height: 1em;
}
.md-steppers-linear .md-stepper-indicator {
  cursor: default;
}
.md-steppers-linear .md-stepper-indicator.md-editable.md-completed:hover {
  cursor: pointer;
}
.md-steppers-linear .md-stepper-indicator.md-editable.md-completed:hover,
.md-steppers-linear
  .md-stepper-indicator.md-editable.md-completed:hover
  .md-stepper-indicator-wrapper {
  background-color: #f6f6f6;
}
.md-steppers-linear
  .md-stepper-indicator.md-editable.md-completed:hover
  .md-stepper-number:before,
.md-steppers-linear
  .md-stepper-indicator.md-editable.md-completed:hover
  .md-stepper-number:after {
  background-color: #f6f6f6;
}
.md-steppers-linear .md-stepper-title {
  color: rgba(0, 0, 0, 0.26);
}
.md-steppers-linear .md-stepper-title small:not(.md-stepper-error-message) {
  color: rgba(0, 0, 0, 0.26);
}
.md-steppers-alternative:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):not(.md-completed):hover
  .md-stepper-indicator-wrapper {
  background: none;
}
.md-steppers-alternative:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):not(.md-completed):hover
  .md-stepper-number:before,
.md-steppers-alternative:not(.md-steppers-linear)
  .md-stepper-indicator:not(.md-active):not(.md-completed):hover
  .md-stepper-number:after {
  background-color: #f6f6f6;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-indicator {
  min-height: 104px;
  padding: 24px 16px;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-indicator
  .md-stepper-indicator-wrapper {
  padding: 0;
}
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-indicator:first-child {
  padding-left: 24px;
}
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-indicator:first-child:after {
  left: 50%;
}
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-indicator:last-child {
  padding-right: 24px;
}
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-indicator:last-child:after {
  right: 50%;
  left: auto;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-indicator:after {
  margin-top: 0;
  top: 36px;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-indicator-wrapper {
  padding: 0 16px;
  min-height: 50px;
  -ms-flex-flow: column nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column nowrap;
  background: none;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-number,
.md-steppers-alternative .md-steppers-horizontal .md-stepper-error-indicator {
  position: relative;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-number:before,
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-error-indicator:before,
.md-steppers-alternative .md-steppers-horizontal .md-stepper-number:after,
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-error-indicator:after {
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  content: ' ';
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-number:before,
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-error-indicator:before {
  left: -8px;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-number:after,
.md-steppers-alternative
  .md-steppers-horizontal
  .md-stepper-error-indicator:after {
  right: -8px;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-title {
  margin-top: 16px;
  line-height: 18px;
  text-align: center;
}
.md-steppers-alternative .md-steppers-horizontal .md-stepper-title small {
  text-align: center;
}
.md-steppers:not(.md-steppers-vertical)
  .md-steppers-header.md-steppers-vertical {
  display: none;
}
.md-steppers-vertical .md-stepper {
  padding: 8px 24px;
}
.md-steppers-vertical .md-steppers-scope {
  margin-left: 20px;
  border-left: 1px solid #e8e8e8;
  padding-left: 20px;
}
.md-steppers-vertical .md-steppers-header.md-steppers-horizontal {
  display: none;
}
.md-steppers-mobile-header,
.md-stepper-feedback-message {
  display: none;
}
@media (max-width: 599px) {
  .md-steppers-mobile-step-text:not(.md-steppers-vertical) .md-stepper {
    padding: 0;
  }
  .md-steppers-mobile-step-text:not(.md-steppers-vertical)
    .md-steppers-mobile-header {
    display: block;
  }
  .md-steppers-mobile-step-text:not(.md-steppers-vertical)
    .md-stepper-feedback-message {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
  }
  .md-steppers-mobile-step-text:not(.md-steppers-vertical)
    .md-steppers-header.md-steppers-horizontal {
    display: none;
  }
  .md-steppers-mobile-step-text:not(.md-steppers-vertical) md-step-actions {
    background: #f6f6f6 !important;
    color: #202020 !important;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
            align-items: stretch;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px,
      rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px,
      rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
  }
}
.md-steppers-header-region {
  position: relative;
}
.md-stepper-feedback-message {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 10px;
  z-index: 2;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.md-steppers {
  position: relative;
}
md-step-body {
  position: relative;
  overflow: auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.md-step-body-loading {
  display: none;
}
.md-steppers-has-feedback .md-steppers-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
.md-steppers-has-feedback .md-step-body-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 90;
  opacity: 0.75;
  background-color: white;
}
.md-steppers-has-feedback .md-step-body-loading {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 91;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  max-width: 100%;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
}
